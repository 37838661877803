<template>
  <div class="scene-wrap">
    <!-- <h1>{{ pinpoints[selectedPoint - 1].title }}</h1> -->
    <!-- <p
        v-for="anim in animations"
        class="anim-select"
        @click="playAnim(anim)"
      >
        {{ anim }}
      </p> -->

    <model-viewer
      id="hotspot-demo"
      class="viewer"
      ar
      ar-modes="webxr"
      exposure="0.5"
      camera-controls
      shadow-intensity="3"
      :src="`models/${models[currentModel].name}.glb`"
      style="--poster-color: #ffffff00F"
      alt="A 3D model of an astronaut."
      autoplay
      @load="updateHotspot({ name: point.name, position: point.pos, normal })"
    >
      <button
        v-for="(point, i) in models[currentModel].points"
        :key="i"
        :slot="`hotspot-${point.name}`"
        @loaded="
          updateHotspot({ name: point.name, position: point.pos, normal })
        "
        :data-position="point.pos"
      >
        <div id="annotation">
          <h2 class="title">{{ point.name }}</h2>
          <p class="paragraph">{{ point.title }}</p>
        </div>
      </button>
    </model-viewer>
  </div>
</template>

<script>
export default {
  name: "Scene",
  props: ["models", "currentModel"],
  watch: {
    currentModel: function () {
      //  updateHotspots
    },
  },
  data: () => {
    return {
      selectedPoint: null,
      animations: null,
      currentAnim: "inner core|Take 001|BaseLayer",
    };
  },
  mounted: function () {},
  methods: {
    loaded: function () {
      const names = document.querySelector("model-viewer").availableAnimations;
      console.log(names);
      this.animations = names;
      console.log(this.animations);
    },
    playAnim: function (anim) {
      let model = document.querySelector("model-viewer");
      model.play();
      model.currentAnim;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.viewer,
.scene-wrap {
  width: 100%;
  height: 100%;
}
.scene-wrap {
  display: flex;
}
.info {
  // position: absolute;
  // z-index: 5;
  // left: 0;
  // top: 0;
}
button {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 3px solid #32d4e3;
  background-color: unset;
  box-sizing: border-box;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 40%;
    border-radius: 20px;
    border: 3px solid #32d4e3;
  }
}

// button[slot="hotspot-hand"] {
//   --min-hotspot-opacity: 0;
//   background-color: red;
// }

// button[slot="hotspot-foot"]:not([data-visible]) {
//   background-color: transparent;
//   border: 3px solid blue;
// }

#annotation {
  background-color: #303030;
  border: 3px solid #32d4e3;
  color: white;
  position: absolute;
  transform: translate(10px, 20px);
  padding: 10px;
  min-width: 200px;
  .title {
    font-size: 2rem;
  }
  .paragraph {
    font-size: 1rem;
  }
}
/* This keeps child nodes hidden while the element loads */
:not(:defined) > * {
  display: none;
}
.anim-select {
  border: 1px solid white;
}
</style>
