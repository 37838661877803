<template>
  <div class="UI light">
    <div :class="`dropdown ${menuOpen ? 'open' : 'closed'}`">
      <button :class="`toggle-btn ${menuOpen ? 'toggle-open' : ''}`" @click="menuOpen = !menuOpen">
        <h2>{{ menuOpen ? "Close" : "Menu" }}</h2>
      </button>
      <div class="content" v-if="menuOpen">
        <div class="center select">
          <vs-select placeholder="Select" v-model="currentModel">
            <vs-option
              :label="model.name"
              :value="i"
              v-for="(model, i) in models"
              :key="i"
              color="primary"
            >
              {{ model.name }}
            </vs-option>
          </vs-select>
        </div>
        <div class="actions">
          <button class="action">Menu</button>
          <button class="action">Random object</button>
          <button class="action">Categories</button>
          <button class="action">Support the project</button>
        </div>
      </div>
    </div>
    <!-- <div class="overlay"><h1>Web ❤️ 3D</h1></div> -->
  </div>
</template>

<script>
export default {
  name: "UI",
  watch: {
    currentModel: function () {
      this.$emit("update-model", this.currentModel);
    },
  },
  props: ["models"],
  data: () => {
    return {
      menuOpen: false,
      currentModel: 0,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button {
  border: unset;
  background: unset;
}
.light {
  color: white;
}
.dropdown {
  position: absolute;
  top: 0;
  left: 20px;
  min-width: 300px;
  height: 40px;
  background: #303030;
  padding: 25px;
  border-radius: 0 0 40px 40px;
  transition: 0.4s;
  z-index: 3;
  h2 {
    margin: 0;
  }
  &.closed {
    cursor: pointer;
  }
  &.open {
    cursor: pointer;
    height: 100vh;
    border-radius: 0;
  }
}
.select {
  margin-bottom: 60px;
}
.toggle-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: white;
  &.toggle-open {
    background: black;
    border-radius: 50px 50px 0 0 ;
  }
}
.content {
  margin: 0 auto;
  padding-top: 50px;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .action {
    font-size: 1.4rem;
    margin-bottom: 20px;
    padding-bottom: 3px;
  color: white;

    &:hover {
      padding-bottom: 0px;
      border-bottom: 3px solid #32d4e3;
    }
  }
}
</style>
