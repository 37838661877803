<template>
  <div class="main">
    <!-- <div class="overlay"><h1>Web ❤️ 3D</h1></div> -->
    <UI :models="models" @update-model="currentModel = $event" />
    <Scene :models="models" :currentModel="currentModel" />
  </div>
</template>

<script>
import Scene from "@/components/Scene.vue";
import UI from "@/components/UI.vue";
export default {
  name: "Main",
  components: {
    Scene,
    UI,
  },
  data: () => {
    return {
      currentModel: 0,
      models: [
        {
          name: "Robot",
          points: [
            {
              hover: false,
              name: "eye",
              pos: "0.5 5.45 0.35",
              title: "Eye made of optic lens",
            },
            {
              hover: false,
              name: "hand",
              pos: "-1.5 3.1 1",
              title: "Hand built out of scraps",
            },
            {
              hover: false,
              name: "foot",
              pos: "1.5 2.1 1.17",
              title: "Split foot made out of titanium for maximum friction",
            },
          ],
        },
        // {
        //   name: "earth",
        //   points: [
        //     {
        //       hover: false,
        //       name: "eye",
        //       pos: "0.5 5.75 0.35",
        //       title: "Eye made of optic lens",
        //     },
        //     {
        //       hover: false,
        //       name: "hand",
        //       pos: "-1.5 3.1 1",
        //       title: "Hand built out of scraps",
        //     },
        //     {
        //       hover: false,
        //       name: "foot",
        //       pos: "1.5 0.1 0.17",
        //       title: "Split foot made out of titanium for maximum friction",
        //     },
        //   ],
        // },

        {
          name: "Viking boat",
          points: [
            {
              hover: false,
              name: "sails",
              pos: "0 0 0",
              title: "Sails of the ship",
            },
            {
              hover: false,
              name: "dragon",
              pos: "0 1.1 2.12",
              title: "Wooden dragon head",
            },
            {
              hover: false,
              name: "paddle",
              pos: "0.16 0.1 0.17",
              title: "Paddle of the ship",
            },
          ],
        },
        {
          name: "Pistol",
          points: [
            {
              hover: false,
              name: "sails",
              pos: "0 1.75 0.35",
              title: "Sails of the ship",
            },
            {
              hover: false,
              name: "dragon",
              pos: "0 1.1 2.12",
              title: "Wooden dragon head",
            },
            {
              hover: false,
              name: "paddle",
              pos: "0.16 0.1 0.17",
              title: "Paddle of the ship",
            },
          ],
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  width: 99vw;
  height: 95vh;
}
.select {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
}
</style>
